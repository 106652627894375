declare const window: any

class ImageBrightnessDetector {
    static init() {
        window.ImageBrightnessDetector = this
    }

    static getBrightnessLevel = (imageUrl: string) => {
        return new Promise((resolve, reject) => {
            const imageElement = new Image()
            imageElement.crossOrigin = 'Anonymous'

            imageElement.onload = () => {
                let totalPixelCount = 0

                // Create a canvas to extract pixel data from the image
                const canvasElement = document.createElement('canvas')
                if (!canvasElement) {
                    return
                }

                const canvasContext = canvasElement.getContext('2d')
                if (!canvasContext) {
                    return
                }

                canvasContext.drawImage(imageElement, 0, 0)
                // Extract pixel data
                const imageData = canvasContext.getImageData(
                    0,
                    0,
                    imageElement.width,
                    imageElement.height
                )
                const pixelDataArray = imageData.data

                // Dictionary to store color frequencies
                let colorFrequencyHistogram: any = {}
                let nonTransparentPixelCount = 0

                // Fill the histogram with color frequencies
                for (let ii = 0; ii < pixelDataArray.length; ii += 4) {
                    const redValue = pixelDataArray[ii]
                    const greenValue = pixelDataArray[ii + 1]
                    const blueValue = pixelDataArray[ii + 2]
                    // Transparency
                    const alphaValue = pixelDataArray[ii + 3]

                    // Skip transparent pixels
                    if (alphaValue === 0) {
                        continue
                    }

                    // Use RGBA as the key
                    const colorKey = `${redValue},${greenValue},${blueValue},${alphaValue}`

                    if (!(colorKey in colorFrequencyHistogram)) {
                        colorFrequencyHistogram[colorKey] = 0
                    }

                    colorFrequencyHistogram[colorKey] += 1
                    totalPixelCount++
                }

                // Calculate the average brightness using the histogram
                let totalLuminosity = 0

                for (const colorKey in colorFrequencyHistogram) {
                    const [redValue, greenValue, blueValue, alphaValue] =
                        colorKey.split(',').map(Number)
                    const colorFrequency = colorFrequencyHistogram[colorKey]

                    // Mix colors with transparency
                    const redMixed =
                        redValue * (alphaValue / 255) +
                        255 * (1 - alphaValue / 255)
                    const greenMixed =
                        greenValue * (alphaValue / 255) +
                        255 * (1 - alphaValue / 255)
                    const blueMixed =
                        blueValue * (alphaValue / 255) +
                        255 * (1 - alphaValue / 255)

                    // Calculate brightness for the color
                    const colorBrightness =
                        0.299 * redMixed +
                        0.587 * greenMixed +
                        0.114 * blueMixed

                    totalLuminosity += colorBrightness * colorFrequency
                    nonTransparentPixelCount += colorFrequency
                }

                // Return average brightness level
                const averageBrightnessLevel = Number(
                    (totalLuminosity / nonTransparentPixelCount).toFixed(2)
                )

                resolve(averageBrightnessLevel)
            }

            imageElement.onerror = reject

            imageElement.src = imageUrl
        })
    }
}

export default ImageBrightnessDetector
