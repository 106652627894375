// See more @ https://docs.sentry.io/platforms/javascript

declare const window: any
declare var shared: any

class SentryVendor {
    static init: () => Promise<any> = async () => {
        if (!shared.sentry.dsn) {
            return await Promise.resolve()
        }

        // Config
        const Sentry = await import('@sentry/browser')
        window.Sentry = Sentry

        const sampleRate = shared.app.traces_sample_rate
            ? parseFloat(shared.app.traces_sample_rate)
            : 0.0

        // Sentry init
        Sentry.init({
            enabled: !!shared.sentry.dsn,
            dsn: shared.sentry.dsn,
            debug: shared.sentry.debug,
            environment: shared.app.env,
            sampleRate,
            integrations: [Sentry.browserTracingIntegration()],
        })

        Sentry.setContext('user', {
            id: shared.auth.id,
            name: shared.auth.name,
            email: shared.auth.email,
        })

        return await Promise.resolve()
    }
}

export default SentryVendor
